import React, { useState , useRef} from "react"
import axios from 'axios'

import SEO from "../components/SEO/SEO"
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'

const EMAIL_KEY = process.env.EMAIL_KEY || "kieron123"

const ContactUsPage = ({ location }) => {

	const form = useRef();
	const [ name, setName ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ subject, setSubject ] = useState('')
	const [ enquiry, setEnquiry ] = useState('')
	const [ formSuccess, setFormSuccess ] = useState(false)
	const [ error, setError ] = useState('')

	const handleSubmit = async ( event ) => {
		event.preventDefault()
		form.current.reportValidity();

		const data = {
			name,
			email,
			subject,
			enquiry,
			EMAIL_KEY
		}

		try {
			const result = await axios.post('/api/email', data);

			if ( result.status === 200 ) {
				setFormSuccess( true )
			} 
		} catch( error ) {
			console.log( error )
			setError('Error')
		}
	}
	return(
		<>
			<SEO
				title="Contact Us"
				pathname={ location.pathname } />
			<Breadcrumb pathname={ location.pathname } />
			<div className= "wrapper">
				<div className="register-form">
					<div className="register-form__container">
						<h1 className="register-form__heading">Contact Us</h1>

						{ !formSuccess ? (
							<>
								<p className="register-form__info">
									Please fill out the following form if you have any queries or questions.
								</p>
								<form className="form" onSubmit={ handleSubmit } ref={ form }>
									<div className="form__input-container">
										<label className="form__label" htmlFor="name">Name</label>
										<input
											onChange={ e => { setName(e.target.value) }}
											value={ name }
											className="form__input" id="name" type="text" placeholder="Name" required />
									</div>

									<div className="form__input-container">
										<label className="form__label" htmlFor="email">Email</label>
										<input
											onChange={ e => { setEmail(e.target.value) }}
											value={ email }
											className="form__input" id="email" type="email" placeholder="Email" required />
									</div>

									<div className="form__input-container">
										<label className="form__label" htmlFor="subject">Subject</label>
										<input
											onChange={ e => { setSubject(e.target.value) }}
											value={ subject }
											className="form__input" id="subject" type="text" placeholder="Subject" required />
									</div>

									<div className="form__input-container">
										<label className="form__label" htmlFor="enquiry">Enquiry</label>
										<textarea
											onChange={ e => { setEnquiry(e.target.value) }}
											value={ enquiry }
											className="form__input" id="enquiry" type="text" placeholder="Enquiry" rows="5" required />
									</div>

									<input type="hidden" id="" name="" value=""></input>

									{ ( error.length > 1 ) && (
										<div className="form__error-container">
											<span className="form__error-message">Sorry, we have been unable to send your query.</span>
											<span className="form__error-message">Please try again later.</span>
										</div>
									)}

									<button className="form__submit" type="submit">Submit</button>
								</form>
							</>
						) : (
							<p className="register-form__info">
								Thank you for contacting Hoarders. We will try and respond to your query as soon as possible!
							</p>
						) }

					</div>
				</div>
			</div>
		</>
	)
}

export default ContactUsPage;